import { ApiMapper } from '../ApiMapper';
import { Property } from '../../entities/Property';
import { AddressApiMapper } from '../Address/AddressApiMapper';
import { updateEntityProperties } from '../../entities/services/EntityPropertiesUpdater';

export class PropertyApiMapper extends ApiMapper {
  static entityToApi(searchCriteria) {
    return {
      id: searchCriteria.id,
      full_address: AddressApiMapper.entityToApi(searchCriteria.address),
      excluded: searchCriteria.excluded,
      mls_id: searchCriteria.mlsId,
      property_type: searchCriteria.propertyType,
      size: searchCriteria.size,
      total_size: searchCriteria.totalSize,
      beds: searchCriteria.beds,
      total_baths: searchCriteria.totalBaths,
      baths: searchCriteria.baths,
      days_on_market: searchCriteria.daysOnMarket,
      days_to_close: searchCriteria.daysToClose,
      price: searchCriteria.price,
      price_per_sqft: searchCriteria.pricePerSqft,
      sale_price: searchCriteria.salePrice,
      sale_price_per_sqft: searchCriteria.salePricePerSqft,
      year_built: searchCriteria.yearBuilt,
      coordinates: searchCriteria.coordinates,
      distance: searchCriteria.distance,
      pool: searchCriteria.pool,
      view: searchCriteria.view,
      status: searchCriteria.status,
      sale_type: searchCriteria.saleType,
      description: searchCriteria.description,
      images: searchCriteria.images,
      list_date: searchCriteria.listDate,
      list_date_human: searchCriteria.listDateHuman,
      sale_date: searchCriteria.saleDate,
      sale_date_human: searchCriteria.saleDateHuman,
      hoa_fee: searchCriteria.hoaFee,
      hoa_fee_value: searchCriteria.hoaFeeValue,
      hoa_fee_freq: searchCriteria.hoaFeeFreq,
    };
  }

  static apiToEntity(apiSearchCriteria) {
    return updateEntityProperties(new Property(), {
      id: apiSearchCriteria.id,
      address: AddressApiMapper.apiToEntity(apiSearchCriteria.full_address),
      excluded: apiSearchCriteria.excluded,
      mlsId: apiSearchCriteria.mls_id,
      propertyType: apiSearchCriteria.property_type,
      size: apiSearchCriteria.size,
      sizeDiff: apiSearchCriteria.size_diff,
      totalSize: apiSearchCriteria.total_size,
      beds: apiSearchCriteria.beds,
      totalBaths: apiSearchCriteria.total_baths,
      baths: apiSearchCriteria.baths,
      daysOnMarket: apiSearchCriteria.days_on_market,
      daysToClose: apiSearchCriteria.days_to_close,
      price: apiSearchCriteria.price,
      pricePerSqft: apiSearchCriteria.price_per_sqft,
      salePrice: apiSearchCriteria.sale_price,
      salePricePerSqft: apiSearchCriteria.sale_price_per_sqft,
      yearBuilt: apiSearchCriteria.year_built,
      coordinates: apiSearchCriteria.coordinates,
      distance: apiSearchCriteria.distance,
      distanceKm: apiSearchCriteria.distance_km,
      brokerName: apiSearchCriteria.broker_name,
      virtualTour: apiSearchCriteria.virtual_tour,
      pool: apiSearchCriteria.pool,
      view: apiSearchCriteria.view,
      status: apiSearchCriteria.status,
      description: apiSearchCriteria.description,
      images:
        apiSearchCriteria.images?.length === 0
          ? ['/assets/images/no-property-image.png']
          : apiSearchCriteria.images,
      listDate: apiSearchCriteria.list_date,
      listDateHuman: apiSearchCriteria.list_date_human,
      saleDate: apiSearchCriteria.sale_date,
      saleDateHuman: apiSearchCriteria.sale_date_human,
      hoaFee: apiSearchCriteria.hoa_fee,
      hoaFeeValue: apiSearchCriteria.hoa_fee_value,
      hoaFeeFreq: apiSearchCriteria.hoa_fee_freq,
      disclaimer: apiSearchCriteria.disclaimer,
      partialMatch: apiSearchCriteria.partial_match,
      partialMatchInfo: apiSearchCriteria.partial_match_info,
      singleImage: apiSearchCriteria.image,
      levels: apiSearchCriteria.levels,
      isFixerUpper: apiSearchCriteria.is_fixer_upper,
      isNewConstruction: apiSearchCriteria.is_new_construction,
      isAdult55: apiSearchCriteria.is_adult55,
      isGreenEnergyStar: apiSearchCriteria.is_green_energy_star,
      isHorseProperty: apiSearchCriteria.is_horse_property,
      isGolfCourse: apiSearchCriteria.is_golf_course,
      isMasterOnMain: apiSearchCriteria.is_master_on_main,
      isAirConditioning: apiSearchCriteria.is_air_conditioning,
      isWaterFront: apiSearchCriteria.is_water_front,
      isWaterView: apiSearchCriteria.is_water_view,
      isDeck: apiSearchCriteria.is_deck,
      basement: apiSearchCriteria.basement,
      garage: apiSearchCriteria.garage,
    });
  }
}
