import { Fragment, useState } from 'react';

import { Modal } from '../../../../../components/Modal/Modal';
import { InfoCard, InfoCardTypes } from '../InfoCard/InfoCard';
import { useSellingPaceTookTimeMessage } from '../../../../../../ReportFacade/components/CompsPerformance/SellingPaceGauge/useSellingPaceTookTimeMessage';
import SellingPaceCardContent from '../../../../../../../../../components/Common/SellingPaceCardContent/SellingPaceCardContent';
import SellingPaceSlide from '../../../../../../../../../components/Slide/slides/marketAnalysis/marketOverview/components/sellingPace/web/SellingPaceSlide';
import { useWhiteLabelSettings } from '../../../../../../../../../features/whitelabel/useWhiteLabelSettings';

export const SellingPace = ({ data, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { hasWL, primary } = useWhiteLabelSettings();

  const openPopup = () => {
    setIsOpen(true);
  };

  const description = useSellingPaceTookTimeMessage(data);

  return (
    <Fragment>
      <InfoCard
        headline='Selling Pace'
        type={InfoCardTypes.CUSTOM}
        content={_ => <SellingPaceCardContent data={data} color={!hasWL ? '#6ab4e4' : primary} />}
        background='/assets/images/dashboard/homeinfo/selling-pace-dark.svg'
        backgroundLight='/assets/images/dashboard/homeinfo/selling-pace.svg'
        description={description}
        info='Comparables are tracked over time to compare whether the “days on market” counts are trending up or down.'
        clicked={openPopup}
        className={className}
      />

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <SellingPaceSlide category='traditional' />
      </Modal>
    </Fragment>
  );
};
