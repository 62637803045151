import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InfoCard } from '../InfoCard/InfoCard';
import { Modal } from '../../../../../components/Modal/Modal';
import PricingRangeOfChangeSlide from '../../../../../../../../../components/Slide/slides/marketAnalysis/marketOverview/components/pricingRateOfChange/web/PricingRateOfChangeSlide';

export const RateOfChange = ({ data, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openPopup = () => {
    setIsOpen(true);
  };

  return (
    <Fragment>
      <InfoCard
        headline='Pricing Rate of Change'
        sup={<FontAwesomeIcon icon={['fas', 'percent']} fontSize={20} />}
        content={_ => data?.text}
        description='Average rate of change between listed and recorded price of sold comps'
        background='/assets/images/dashboard/homeinfo/pricing-rate-of-change-dark.svg'
        backgroundLight='/assets/images/dashboard/homeinfo/pricing-rate-of-change.svg'
        info='This measurement tracks both the listing and closed price for all closed comparables and compares to establish if closed comparables are selling above, below or equal to the listing price.'
        clicked={() => openPopup()}
        className={className}
        disabled={!data?.props?.length}
      />

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} light>
        <PricingRangeOfChangeSlide category='traditional' />
      </Modal>
    </Fragment>
  );
};
