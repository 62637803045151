import React from 'react';
import { useAtomValue } from 'jotai';

import { reportPropertiesAtom } from '../../../../../../features/report/state/reportPropertiesAtom';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { InfoCard } from '../../../view/pages/Authenticated/CompResults/CompsPerformance/InfoCard/InfoCard';
import styles from './CommonStyles.module.scss';

export const PricePerSqftGauge = ({ onClick = f => f, oneSheeterSlide = false }) => {
  const color = useUiConfigColorsPrimary();
  const properties = useAtomValue(reportPropertiesAtom);

  let combinedProperties = properties
    .filter(prop => !prop.property.excluded && !!prop.property.size)
    .map(prop => prop.property);

  const calculateAvgPricePerSqft = () => {
    if (!combinedProperties.length) {
      return 'N/A';
    }

    let sum = 0;
    combinedProperties.forEach(property => {
      const pricePerSqft =
        property.status === 'sold' ? property.salePricePerSqft : property.pricePerSqft;
      sum = sum + pricePerSqft;
    });
    return Math.round(sum / combinedProperties.length);
  };

  return (
    <InfoCard
      headline='Price per SQFT'
      sup='$'
      content={_ => (combinedProperties.length ? calculateAvgPricePerSqft() : 'N/A')}
      description='Average price per square foot of all comparable properties'
      background='/assets/images/dashboard/homeinfo/fresh-stale-bg.png'
      info={`Shows the price per SQFT of all comps on a scatter graph.`}
      clicked={onClick}
      borderedCircleColor={color}
      className={styles.card}
      oneSheeterSlide={oneSheeterSlide}
      disabled={!combinedProperties.length}
    />
  );
};
