import React from 'react';

import { useUser } from '../../../../providers/UserProvider';
import { CustomMarker } from '../../../../../Common/CustomMarker/CustomMarker.component';
import { getInfoWindowContent } from './getInfoWindowContent';
import { useTheme } from 'styled-components';
import pinA from '../../../../../../assets/icons/map/pin-a.svg?url';
import pinGrayA from '../../../../../../assets/icons/map/pin-gray-a.svg?url';

interface CustomMarkerProps {
  property: any;
  icon: string;
  isClickable?: boolean;
  subject?: any;
  isPartialMatch?: boolean;
}

export const Marker: React.FC<CustomMarkerProps> = ({
  property,
  icon,
  subject,
  isClickable = false,
  isPartialMatch,
}) => {
  const { isCanadaUser } = useUser();
  const theme = useTheme();

  return (
    <CustomMarker
      key={property.id}
      icon={icon}
      isClickable={isClickable}
      property={property}
      infoWindowContent={getInfoWindowContent({
        property,
        isPartialMatch,
        subject,
        isCanadaUser,
        theme,
      })}
    />
  );
};

interface MarkersProps {
  activeComparables: any[];
  activeNonComparables: any[];
  subject: any;
  showSellerSupply?: boolean;
}

export const Markers: React.FC<MarkersProps> = ({
  showSellerSupply,
  activeComparables,
  activeNonComparables,
  subject,
}) => {
  return (
    <>
      {showSellerSupply && (
        <>
          {activeComparables.map(p => (
            <Marker key={p.id} property={p} icon={pinA} isClickable subject={subject} />
          ))}
          {activeNonComparables.map(p => (
            <Marker
              key={p.id}
              property={p}
              icon={pinGrayA}
              isClickable
              subject={subject}
              isPartialMatch
            />
          ))}
        </>
      )}

      {subject && <Marker property={subject} icon='/assets/images/map/pin-subject.svg' />}
    </>
  );
};
