import React, { Component } from 'react';

import { mapStyles } from './MapStyles';
import classes from './CompettionMap.module.scss';
import { Map } from '../../../../../../components/Map/Map';
import { MoneyFormatter } from '../../../../../../../services/moneyFormatter';
import { getPropertyStatus } from '../../../../../../services/getPropertyStatus';
import { getCompetitionPin } from '../../../../../../../../../../features/report/charts/active-competition/legend/CompetitionPins';
import { getPropertyId } from '../../../../../../../../../../features/report/services/getPropertyId';
import subjectIcon from '../../../../../../../../../../assets/icons/map/pin-subject.svg?url';
import { getMapPinBasedOnStatus } from '../../../../../../../../../../services/getMapPinBasedOnStatus';

class CompettionMap extends Component {
  static defaultProps = {
    moreInfo: null,
    markerScaleSize: {
      x: 29,
      y: 40,
    },
    onCompetitionLoad: f => f,
  };

  map = null;
  google = null;
  bounds = null;
  markers = [];
  oms = null;

  createMarker = ({ lat, lng, excluded, property }, primary, status) => {
    if (!lat || !lng) return;

    const getMarkerIcon = () => {
      if (primary) {
        return subjectIcon;
      }

      const icon = getMapPinBasedOnStatus({
        status,
        isGray: excluded && this.props.noModal,
        hasBtTheme: this.props.hasBtTheme,
      });

      if (excluded && !this.props.noModal) {
        return `/assets/images/map-pointer.png`;
      }

      if (this.props.noModal) {
        return icon;
      }

      return getCompetitionPin(property.daysOnMarket);
    };

    const marker = new window.google.maps.Marker({
      position: { lat, lng },
      map: this.map,
      icon: {
        url: getMarkerIcon(),
        scaledSize: new google.maps.Size(
          this.props.markerScaleSize.x,
          this.props.markerScaleSize.y,
        ),
      },
    });

    this.markers.push(marker);
    this.bounds.extend(marker.getPosition());
    this.map.fitBounds(this.bounds);
    this.oms?.addMarker?.(marker);

    return marker;
  };

  clearAllMarkers() {
    this.markers.forEach(marker => {
      marker.setMap(null);
    });
  }

  addLabelToMarker = (marker, { address, property }, active) => {
    const status = property.status;
    const priceToUse = status === 'sold' ? property.salePrice : property.price;
    const priceToUseFormatted = MoneyFormatter.format(priceToUse);
    const hasAdjustedPrice = !!property?.adjustedPrice;
    const adjustedPrice = hasAdjustedPrice ? MoneyFormatter.format(property.adjustedPrice) : '';
    const infowindow = new window.google.maps.InfoWindow({
      content: `
        <div>
          <div class="${classes.address}">${address}</div>
<div class="${classes.price}">${priceToUse ? `Price: ${priceToUseFormatted}` : ''}</div>
<div class="${
        hasAdjustedPrice ? classes.adjustedPrice : classes.displayNone
      }">${`ADJ Price: ${adjustedPrice}`}</div>
          <button
            class="${classes.infoWindowButton}" style="background-color: ${
              this.props.moreInfoButtonColor
            }"
            onclick="document.dispatchEvent(new CustomEvent('demo', {detail: '${getPropertyId(
              property,
            )}'}))"
          >More Info</button>
        </div>
      `,
    });

    marker.addListener('click', () => {
      infowindow.open(this.map, marker);
    });

    if (active && active.id === getPropertyId(property)) {
      infowindow.open(this.map, marker);
    }
  };

  addLabelToMarkerWithoutButton = (marker, { address, property }, active) => {
    const status = property.status;
    const priceToUse = status === 'sold' ? property.salePrice : property.price;
    const priceToUseFormatted = MoneyFormatter.format(priceToUse);
    const hasAdjustedPrice = !!property?.adjustedPrice;
    const adjustedPrice = hasAdjustedPrice ? MoneyFormatter.format(property.adjustedPrice) : '';
    const infowindow = new window.google.maps.InfoWindow({
      content: `
        <div>
          <div class="${classes.address}">${address}</div>
          <div class="${classes.price}">${priceToUse ? `Price: ${priceToUseFormatted}` : ''}</div>
          <div class="${
            hasAdjustedPrice ? classes.adjustedPrice : classes.displayNone
          }">${`ADJ Price: ${adjustedPrice}`}</div>
        </div>
      `,
    });

    marker.addListener('click', () => {
      infowindow.open(this.map, marker);
    });

    if (active && active.id === getPropertyId(property)) {
      infowindow.open(this.map, marker);
    }
  };

  onLoadHandler = () => {
    return this.props.onCompetitionLoad?.();
  };

  mapLoad = (map, google, bnd = false) => {
    const { properties, subject, moreInfo, active } = this.props;
    this.map = map;
    this.google = google;
    const bounds = bnd ? bnd : new google.maps.LatLngBounds();
    this.bounds = bounds;
    map.fitBounds(bounds);
    const subjectMarker = this.createMarker(subject, true, 'a');
    this.addLabelToMarkerWithoutButton(subjectMarker, subject);

    // const filteredProps = properties.filter(property => {
    //   return property.property.excluded === false;
    // });
    const filteredProps = properties;
    filteredProps.forEach(property => {
      const status = getPropertyStatus(property.status);
      const marker = this.createMarker(property, false, status);

      if (moreInfo) {
        this.addLabelToMarker(marker, property, active);
      } else {
        this.addLabelToMarkerWithoutButton(marker, property, active);
      }
    });

    document.addEventListener('demo', ({ detail }) => {
      const property = [subject, ...filteredProps].find(
        ({ property }) => `${getPropertyId(property)}` === `${detail}`,
      );

      if (property && moreInfo) {
        moreInfo(property.property);
      }
    });
    this.onLoadHandler();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const propertiesMatch =
      prevProps.properties.length === this.props.properties.length &&
      prevProps.properties.every((value, index) => value === this.props.properties[index]);

    if (!propertiesMatch && this.google) {
      this.clearAllMarkers();
      this.mapLoad(this.map, this.google, this.bounds);
    }
  }

  render() {
    const { subject, style, active } = this.props;
    if (!subject || !subject.lat) {
      return null;
    }
    return (
      <Map
        isPdf={this.props.isPdf}
        options={{
          center: active
            ? { lat: active.lat, lng: active.lng }
            : { lat: subject.lat, lng: subject.lng },
          zoom: 15,
          styles: mapStyles,
          disableDefaultUI: true,
        }}
        onMapLoad={(map, google, oms) => {
          this.oms = oms;
          this.mapLoad(map, google);
        }}
        style={style}
      />
    );
  }
}

export { CompettionMap };
