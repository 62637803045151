import { atom, getDefaultStore, useAtomValue } from 'jotai';
import { useSelector, useStore } from 'react-redux';
import { customMarkersAtom } from '../../features/report/state/customMarkersAtom';

export const useReportData = () => {
  const store = useStore();

  const getReportData = () => {
    const reportData = store.getState();
    const defaultStore = getDefaultStore();

    const customMarkers = defaultStore.get(customMarkersAtom);

    return {
      report: (reportData as any)?.report,
      result: {
        ...(reportData as any)?.result,
        customMarkers,
      },
      search: (reportData as any)?.search,
    };
  };

  return getReportData;
};
