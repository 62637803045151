import React, { useMemo } from 'react';
import { CATEGORIES } from '../../../../../../../constants/categories';
import { Report } from '../../../../../../../pages/PresentationCreate/dash';
import { useCategory } from '../../../../../../../providers/providers/CategoryProvider';
import { useUser } from '../../../../../providers/UserProvider';
import { PdfFrame } from '../../../../../components/SlideFrame/frames/pdf/PdfFrame';
import { LuxuryPropertyImage } from './LuxuryPropertyImage/LuxuryPropertyImage.component';
import { BrokerContactInfo } from '../components/BrokerContactInfo/BrokerContactInfo';
import {
  Address,
  PropertyAddressFrame,
  PropertyAddressWrapper,
  PropertyInfoItem,
  PropertyInfoItems,
  PropertyPreview,
  PropertyPreviewContainer,
  PropertyPreviewWrapper,
  ReportInfo,
} from './LuxuryCoverPdf.styles';
import { useSlide } from '../../../../../providers/SlideProvider';
import { useParams } from 'react-router-dom';
import { usePresentation } from '../../../../../../../hooks/usePresentation';
import { TAddress } from '../../../../../../../types';
import { NumberFormatter } from '../../../../../../../pages/PresentationCreate/dash/services/numberFormatter';

export const LuxuryCoverRegularPdf: React.FC = () => {
  const { category } = useCategory();
  const updatedAt = Report.data.report.useReportUpdatedAt();
  const { address, addressShort, beds, size, totalBaths } = Report.data.subject.useSubjectInfo();
  const user = useUser();
  const isCanadaUser = user?.isCanadaUser;

  const { hash } = useParams<{ hash: string }>();

  const { data: presentation } = usePresentation(hash);

  const slide = useSlide();

  const displayImage = slide?.data?.displayImage;
  const image = displayImage && slide?.data?.image ? slide?.data?.image : '';
  const editedTitle = slide?.data?.text ? slide.data.text : '';
  const editedFeatures = slide?.data?.text2 ? slide.data.text2 : '';

  const title = useMemo(() => {
    if (editedTitle) return editedTitle;

    if (addressShort) {
      return `${addressShort}, ${address.city}, ${address.state} ${address.zip}`;
    }

    return presentation?.clientName || '';
  }, [address, addressShort, editedTitle, presentation?.clientName]);

  const renderPropertyInfo = editedFeatures ? (
    <PropertyInfoItems>
      <PropertyInfoItem displayImage={!!image}>{editedFeatures}</PropertyInfoItem>
    </PropertyInfoItems>
  ) : (
    <PropertyInfoItems>
      {!isNaN(parseInt(String(beds))) && (
        <PropertyInfoItem displayImage={!!image}>{beds} bedrooms</PropertyInfoItem>
      )}
      {totalBaths && (
        <PropertyInfoItem displayImage={!!image}>{totalBaths} bathrooms</PropertyInfoItem>
      )}
      {!isCanadaUser && size && (
        <PropertyInfoItem displayImage={!!image}>
          {NumberFormatter.format(size)} SQFT living area
        </PropertyInfoItem>
      )}
    </PropertyInfoItems>
  );

  return (
    <PdfFrame category={category ?? CATEGORIES.TRADITIONAL} isCover>
      <PropertyPreviewContainer>
        <ReportInfo>
          Report valid for{' '}
          {new Date(updatedAt).toLocaleDateString('en-US', {
            month: 'long',
            year: 'numeric',
            day: 'numeric',
          })}
        </ReportInfo>
        <PropertyPreviewWrapper>
          <PropertyPreview>
            <LuxuryPropertyImage
              isPdf
              image={slide?.data?.image}
              address={!displayImage ? title : ''}
            />

            <PropertyAddressFrame>
              <PropertyAddressWrapper>
                {displayImage && <Address>{title}</Address>}
                {renderPropertyInfo}
              </PropertyAddressWrapper>
            </PropertyAddressFrame>
          </PropertyPreview>
        </PropertyPreviewWrapper>
      </PropertyPreviewContainer>
      <BrokerContactInfo isLuxury />
    </PdfFrame>
  );
};
