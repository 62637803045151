import React, { useEffect, useRef } from 'react';

import { TProperty } from '../../../../../../../types';
import { useAtom, useSetAtom } from 'jotai';
import { selectedPropertyAtom } from '../../../../../state/selectedPropertyAtom';
import { compViewModeAtom } from '../../../../../state/compViewModeAtom';
import styled from 'styled-components';
import { Details } from './Details';
import { PriceAndActions } from './PriceAndActions/PriceAndActions';
import { ItemContainer } from './ItemContainer';
import { getPropertyId } from '../../../../../services/getPropertyId';

interface Props {
  property: TProperty;
  propertiesRef: React.MutableRefObject<HTMLElement | null>;
}

export const HomePreview = ({ property, propertiesRef }: Props) => {
  const [selectedProperty, setSelectedProperty] = useAtom(selectedPropertyAtom);
  const setViewMode = useSetAtom(compViewModeAtom);

  const isSelected =
    selectedProperty && getPropertyId(selectedProperty) === getPropertyId(property);

  const onClick = () => {
    setSelectedProperty(property);
    setViewMode('slider');
  };

  return (
    <ItemContainer propertiesRef={propertiesRef} onClick={onClick} isSelected={isSelected || false}>
      <HomePicture>
        <Image src={property?.images?.[0] || '/assets/images/no-property-image.png'} alt='' />
      </HomePicture>
      <Details property={property} />
      <PriceAndActions property={property} />
    </ItemContainer>
  );
};

const HomePicture = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Image = styled.img`
  width: 32px;
  height: 32px;
  object-fit: cover;
  object-position: center;
`;
