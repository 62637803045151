import * as creators from './actions';
import { operations as reportOperations } from '../report';
import { saveState } from '../../../../../store/statePersister';
import { CurrentUserService } from '../../../../../services/CurrentUserService';
import { getPropertyStatus } from '../../view/services/getPropertyStatus';
import {
  ACTIVE,
  CLOSED,
  PENDING,
  TRENDS_KEYS,
} from '../../../../../features/report/results-tuning/trends/trendsConfig';
import {
  LARGER_FLAGS_FOOTAGE,
  SMALLER_FLAGS_FOOTAGE,
} from '../../../../../features/report/results-tuning/outliers/flagsConfig';
import { getCompsConfigAsCollection } from '../../../../../features/report/results-tuning/services/getCompsConfigAsCollection';
import { getPropertyId } from '../../../../../features/report/services/getPropertyId';
import { propertyToMarker } from '../../../../../features/report/state/highlighted-properties/propertyToMarker';
/* eslint-disable */

export const setSelectedCategories = categories => creators.setSelectedCategories(categories);
export const setFilteredProperties = propertiesOrNull =>
  creators.setFilteredProperties(propertiesOrNull);
export const setHighlighted = properties => creators.setHighlighted(properties);
export const setExcluded = properties => creators.setExcluded(properties);
export const setThumbsUp = properties => creators.setThumbsUp(properties);
export const setCustomEstimates = estimates => creators.setCustomEstimates(estimates);

export const resetResult = () => dispatch => {
  dispatch(setCustomEstimates([]));
  dispatch(setSelectedCategories([]));
  dispatch(setFilteredProperties(null));
  dispatch(setHighlighted([]));
  dispatch(setExcluded([]));
  dispatch(setThumbsUp([]));
};

export const cacheStore = () => (dispatch, getStore) => {
  saveState(getStore());
};

export const toggleHighlight = (property, highlighted, pricePerSqftMode) => {
  const propertyId = getPropertyId(property);
  const highlightsCopy = highlighted.map(item => ({ ...item }));
  const positionInList = highlightsCopy.findIndex(single => single.id === propertyId);

  if (positionInList !== -1) {
    highlightsCopy.splice(positionInList, 1);
    return highlightsCopy;
  }

  highlightsCopy.push({ ...property });
  if (pricePerSqftMode) {
    highlightsCopy.sort((a, b) => a.pricePerSqft - b.pricePerSqft);
  } else {
    highlightsCopy.sort((a, b) => a.price - b.price);
  }

  return highlightsCopy;
};

export const thumbsUpToggle =
  (property, removeFromHighlighted = true, pricePerSqftMode) =>
  (dispatch, getState) => {
    const { result } = getState();
    const { thumbsUp } = result;

    if (removeFromHighlighted) {
      onHighlight(property, false, pricePerSqftMode)(dispatch, getState);
    }

    const propertyId = getPropertyId(property);
    const positionInList = thumbsUp.findIndex(single => getPropertyId(single) === propertyId);

    if (positionInList !== -1) {
      thumbsUp.splice(positionInList, 1);
      dispatch(setThumbsUp(thumbsUp));
      return;
    }

    thumbsUp.push(propertyToMarker(property));
    dispatch(setThumbsUp(thumbsUp));
  };

export const onHighlight =
  (property, deleteFromThumbsUp = true, pricePerSqftMode) =>
  (dispatch, getState) => {
    const { result } = getState();
    const { highlighted } = result;

    if (deleteFromThumbsUp) {
      thumbsUpToggle(property, false, pricePerSqftMode)(dispatch, getState);
    }

    const newHighlighted = toggleHighlight(property, highlighted, pricePerSqftMode);
    dispatch(setHighlighted(newHighlighted));
  };

const thumbsUpById = (propertyId, dispatch, getState, pricePerSqftMode) => {
  const { report } = getState();

  const { active, pending, sold, comingSoon } = report.reportDetailed.properties;
  const property = [...active, ...pending, ...sold, ...comingSoon].find(
    e => getPropertyId(e) === propertyId,
  );

  if (!property) return;

  thumbsUpToggle(property, false, pricePerSqftMode)(dispatch, getState);
};

const toggleExcluded = (propertyId, dispatch, getState, pricePerSqftMode) => {
  const { excluded: excludedComparables } = getState().result;

  const isCurrentlyExcluded = excludedComparables.some(e => e === propertyId);
  if (!isCurrentlyExcluded) return [...excludedComparables, propertyId];

  thumbsUpById(propertyId, dispatch, getState, pricePerSqftMode);

  return excludedComparables.filter(e => e !== propertyId);
};

export const setDefaultThumbsUp = () => (dispatch, getState) => {
  const { report, result } = getState();
  const { properties } = report.reportDetailed;

  const highlightedMap = result.highlighted.reduce((map, h) => ({ ...map, [h.id]: h.id }), {});

  const thumbsUp = [
    ...properties.active,
    ...properties.pending,
    ...properties.sold,
    ...properties.comingSoon,
  ]
    .filter(property => !highlightedMap[getPropertyId(property)])
    .filter(property => !result.excluded.includes(getPropertyId(property)));

  thumbsUp.sort((a, b) => a.price - b.price);

  dispatch(setThumbsUp(thumbsUp));
};

const getDistinctListOfHeightlights = (oldHeighlights, newDefaultHeighlights) => {
  const manualHeighlights = (oldHeighlights || []).filter(h => !h.label);
  const newDefaultHeighlightsMap = newDefaultHeighlights.reduce(
    (map, h) => ({ ...map, [h.id]: h }),
    {},
  );

  const distinctHeighlights = manualHeighlights.filter(h => !newDefaultHeighlightsMap[h.id]);
  return [...newDefaultHeighlights, ...distinctHeighlights];
};

const getThumbsUpsWithoutHeighlights = (thumbs, highlights) => {
  const highlightsMap = highlights.reduce((map, h) => ({ ...map, [h.id]: h }), {});
  return thumbs.filter(t => !highlightsMap[t.id]);
};

export const onExclude =
  (propertyId, presentationType, presentationId, setExcludeInProgress, pricePerSqftMode) =>
  async (dispatch, getState) => {
    const { result } = getState();
    const { highlighted, thumbsUp } = result;

    setExcludeInProgress(propertyId);
    if (highlighted.find(property => getPropertyId(property) === propertyId)) {
      onHighlight({ id: propertyId }, false, pricePerSqftMode)(dispatch, getState);
    }

    if (thumbsUp.find(property => getPropertyId(property) === propertyId)) {
      thumbsUpToggle({ id: propertyId }, false, pricePerSqftMode)(dispatch, getState);
    }

    const newExcluded = toggleExcluded(propertyId, dispatch, getState, pricePerSqftMode);
    dispatch(setExcluded(newExcluded));

    return reportOperations
      .updateReport({
        excluded_ids: newExcluded,
        presentation_type: presentationType,
        presentation_id: presentationId,
      })(dispatch, getState)
      .then(() => {
        const { result, report } = getState();

        const { highlights, thumbs } = getDefaultHighlightsAndThumbsUp(
          report.reportDetailed.properties,
          {
            ...result,
            excluded: newExcluded,
          },
        );

        const distinctHeighlights = getDistinctListOfHeightlights(result.highlighted, highlights);
        dispatch(setHighlighted(distinctHeighlights));

        const thumbsWithoutHeighlights = getThumbsUpsWithoutHeighlights(
          thumbs,
          distinctHeighlights,
        );
        dispatch(setThumbsUp(thumbsWithoutHeighlights));

        setExcludeInProgress(false);
      });
  };

export const refreshBuild = (presentationType, presentationId) => async (dispatch, getState) => {
  const { result } = getState();
  const excludedIds = result?.excluded || [];

  return reportOperations.updateReport({
    excluded_ids: excludedIds,
    presentation_type: presentationType,
    presentation_id: presentationId,
  })(dispatch, getState);
};

const getStatusIndicator = status => getPropertyStatus(status);

const getHighlightData = property => {
  const {
    id,
    address,
    price,
    pricePerSqft,
    salePrice,
    salePricePerSqft,
    status,
    label = '',
  } = property;
  const priceToReturn = status === 'sold' ? salePrice : price;
  const pricePerSqftToReturn = status === 'sold' ? salePricePerSqft : pricePerSqft;

  return {
    id,
    label,
    price: priceToReturn,
    pricePerSqft: pricePerSqftToReturn,
    name: address.deliveryLine,
    beds: property.beds,
    size: property.size,
    distance: property.distance,
    status: getStatusIndicator(status),
  };
};

const findHighlightProperty = (properties, excluded, sortingFunc, label) => {
  const excludedMap = excluded?.reduce((map, pId) => ({ ...map, [pId]: pId }), {}) ?? {};
  const nonExcludedProperties = properties?.filter(
    property => !excludedMap[getPropertyId(property)],
  );

  const [firstProperty, ...restOfProperties] = nonExcludedProperties.sort(sortingFunc);

  const highlight = !firstProperty ? null : getHighlightData({ label, ...firstProperty });

  const thumbsUp = restOfProperties.map(getHighlightData);

  return { highlight, thumbsUp };
};

export const getDefaultHighlightsAndThumbsUp = (properties, result) => {
  const mostRecentActive = findHighlightProperty(
    properties.active,
    result.excluded || [],
    (a, b) => b.listDate - a.listDate,
    'Most Recent',
  );
  const mostRecentSold = findHighlightProperty(
    properties.sold,
    result.excluded || [],
    (a, b) => b.saleDate - a.saleDate,
    'Most Recent',
  );
  const closestActive = findHighlightProperty(
    properties.active,
    result.excluded || [],
    (a, b) => a.distance - b.distance,
    'Closest Active',
  );
  const closestSold = findHighlightProperty(
    properties.sold,
    result.excluded || [],
    (a, b) => a.distance - b.distance,
    'Closest Sold',
  );
  const highestActive = findHighlightProperty(
    properties.active,
    result.excluded || [],
    (a, b) => b.price - a.price,
    'Highest Active',
  );
  const highestSold = findHighlightProperty(
    properties.sold,
    result.excluded || [],
    (a, b) => b.salePrice - a.salePrice,
    'Highest Sold',
  );

  const forHighlighting = [
    mostRecentActive?.highlight,
    mostRecentSold?.highlight,
    closestActive?.highlight,
    closestSold?.highlight,
    highestActive?.highlight,
    highestSold?.highlight,
  ]
    .filter(property => !!property)
    .filter(property => !result.excluded.includes(getPropertyId(property)));
  forHighlighting.sort((a, b) => a.price - b.price);

  const thumbsUp = [
    ...mostRecentActive?.thumbsUp,
    ...mostRecentSold?.thumbsUp,
    ...closestActive?.thumbsUp,
    ...closestSold?.thumbsUp,
    ...highestActive?.thumbsUp,
    ...highestSold?.thumbsUp,
    ...properties?.pending,
    ...(properties?.comingSoon ?? []),
  ]
    .filter(property => !!property)
    .filter(property => !result.excluded.includes(getPropertyId(property)));
  thumbsUp.sort((a, b) => a.price - b.price);

  const uniqueHighlightsMap = {};
  const uniqueThumbsMap = {};

  const highlightsToPass = [];
  const thumbsToPass = [];

  forHighlighting.forEach(property => {
    if (uniqueHighlightsMap[getPropertyId(property)]) return;
    uniqueHighlightsMap[getPropertyId(property)] = true;
    uniqueThumbsMap[getPropertyId(property)] = true; // highlighted cannot be thumb up at the same time

    highlightsToPass.push(property);
  });

  thumbsUp.forEach(property => {
    if (uniqueThumbsMap[getPropertyId(property)]) return;
    uniqueThumbsMap[getPropertyId(property)] = true;

    thumbsToPass.push(property);
  });

  return {
    highlights: highlightsToPass,
    thumbs: thumbsToPass,
  };
};

export const setDefaultHighlights = () => (dispatch, getState) => {
  const { report, result } = getState();
  const { properties } = report.reportDetailed;

  const { highlights, thumbs } = getDefaultHighlightsAndThumbsUp(properties, result);

  dispatch(setHighlighted(highlights));
  dispatch(setThumbsUp(thumbs));
};

export const setHighlightsByIds = ids => (dispatch, getState) => {
  const { report } = getState();
  const properties = report.reportDetailed.properties.sold
    .concat(report.reportDetailed.properties.pending)
    .concat(report.reportDetailed.properties.comingSoon)
    .concat(report.reportDetailed.properties.active);
  if (properties.length === 0) {
    return;
  }

  ids.forEach(propertyId => {
    const getStatusIndicator = status => (status === 'sold' ? 'C' : status.charAt(0));
    let property = properties.filter(property => getPropertyId(property) === propertyId);
    if (property.length === 0) {
      return;
    }
    property = property[0];
    const {
      id,
      address,
      price,
      pricePerSqft,
      salePrice,
      salePricePerSqft,
      status,
      beds,
      size,
      distance,
      adjustedPrice,
    } = property;
    const priceToReturn = status === 'sold' ? salePrice : price;
    const pricePerSqftToReturn = status === 'sold' ? salePricePerSqft : pricePerSqft;

    onHighlight({
      id,
      price: priceToReturn,
      pricePerSqft: pricePerSqftToReturn,
      name: address.deliveryLine,
      status: getStatusIndicator(status),
      beds,
      size,
      distance,
    })(dispatch, getState);
  });
};

export const setCategoriesByIds =
  (ids, customCategories = []) =>
  (dispatch, getState) => {
    const { report } = getState();
    const reportDetailed = report.reportDetailed;
    const categories = {
      ...reportDetailed.flags,
      ...reportDetailed.compAverages,
      ...reportDetailed.estimates,
    };
    const comps = getCompsConfigAsCollection();
    let selections = [];
    ids.forEach(categoryId => {
      //Get values for these key.
      const value = categories[categoryId];
      const valuePerSqft = categories[categoryId + '_per_sqft'];
      //Map other info about this key
      if (value) {
        const category = comps
          .filter(configOption => configOption.id === categoryId)
          .map(config => ({
            ...config,
            icon: config.icon,
            name: config.labelHtml,
            value: value,
            valuePerSqft: valuePerSqft,
          }));
        selections = selections.concat(category);
      }
    });
    selections = selections.concat(customCategories);
    selections.sort((a, b) => a.value - b.value);
    dispatch(setSelectedCategories(selections));
  };

const buildConfSelection = (compValues, config) => ({
  icon: config.icon,
  id: config.id,
  name: config.labelHtml,
  value: compValues[config.id],
  valuePerSqft: compValues[config.idPerSqft],
  customizedData: config.customizedData,
});
export const setDefaultCategories = () => (dispatch, getState) => {
  const { report } = getState();
  const { compAverages, estimates, properties, flags } = report.reportDetailed;

  let selections = [];

  if (Boolean(properties?.sold?.filter(p => !p.excluded)?.length)) {
    selections.push(buildConfSelection(compAverages, CLOSED[TRENDS_KEYS.AVERAGE]));
  }

  if (Boolean(properties?.active?.filter(p => !p.excluded)?.length)) {
    selections.push(buildConfSelection(compAverages, ACTIVE[TRENDS_KEYS.AVERAGE]));
  }

  if (Boolean(properties?.pending?.filter(p => !p.excluded)?.length)) {
    selections.push(buildConfSelection(compAverages, PENDING[TRENDS_KEYS.AVERAGE]));
  }

  if (flags[LARGER_FLAGS_FOOTAGE.FROM_25_TO_50] > 0) {
    selections.push(buildConfSelection(flags, LARGER_FLAGS_FOOTAGE.FROM_25_TO_50));
  }

  if (flags[SMALLER_FLAGS_FOOTAGE.FROM_25_TO_50] > 0) {
    selections.push(buildConfSelection(flags, SMALLER_FLAGS_FOOTAGE.FROM_25_TO_50));
  }

  selections.sort((a, b) => a.value - b.value);

  dispatch(setSelectedCategories(selections));
};

export const setDefaultResultState = () => (dispatch, getState) => {
  dispatch(setHighlighted([]));
  dispatch(setSelectedCategories([]));
  dispatch(setExcluded([]));
  dispatch(setCustomEstimates([]));

  setDefaultHighlights()(dispatch, getState);
  setDefaultCategories()(dispatch, getState);
};
/* eslint-enable */
