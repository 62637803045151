import { useMemo } from 'react';

import { useUpdateAtomOnChange } from '../../../hooks/useUpdateAtomOnChange';
import { extractActiveShelfLife } from '../services/extractActiveShelfLife';
import { extractPricingFavorability } from '../services/extractPricingFavorability';
import { extractPricingRateOfChange } from '../services/extractPricingRateOfChange';
import { extractSellingPace } from '../services/extractSellingPace';
import { activeShelfLifeAtom } from '../state/activeShelfLifeAtom';
import { pricingFavorabilityAtom } from '../state/pricingFavorabilityAtom';
import { pricingRateOfChangeAtom } from '../state/pricingRateOfChangeAtom';
import { sellingPaceAtom } from '../state/sellingPaceAtom';
import { useGetGraphs } from './useGetGraphs';

export const useSetGraphs = () => {
  const graphs = useGetGraphs();

  const sellingPace = useMemo(() => extractSellingPace(graphs), [graphs]);
  useUpdateAtomOnChange(sellingPaceAtom, sellingPace);

  const activeShelfLife = useMemo(() => extractActiveShelfLife(graphs), [graphs]);
  useUpdateAtomOnChange(activeShelfLifeAtom, activeShelfLife);

  const pricingFavorability = useMemo(() => extractPricingFavorability(graphs), [graphs]);
  useUpdateAtomOnChange(pricingFavorabilityAtom, pricingFavorability);

  const pricingRateOfChange = useMemo(() => extractPricingRateOfChange(graphs), [graphs]);
  useUpdateAtomOnChange(pricingRateOfChangeAtom, pricingRateOfChange);
};
