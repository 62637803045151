import React from 'react';
import { useAtomValue } from 'jotai';

import { reportPropertiesAtom } from '../../../../../../features/report/state/reportPropertiesAtom';
import { useUiConfigColorsPrimary } from '../../../../../../providers/providers/UiConfigColorProvider';
import { InfoCard } from '../../../view/pages/Authenticated/CompResults/CompsPerformance/InfoCard/InfoCard';
import { getShelflifeAverage } from '../../../services/getShelfLifeAverage';
import styles from './CommonStyles.module.scss';

export const ShelfLifeGauge = ({ onClick = f => f, oneSheeterSlide = false }) => {
  const color = useUiConfigColorsPrimary();
  const propertiesRaw = useAtomValue(reportPropertiesAtom);
  const properties = propertiesRaw.map(prop => prop.property);

  const value = getShelflifeAverage(properties);

  const disabled = !value || value == '0';
  return (
    <InfoCard
      headline={'Current Active Shelf Life'}
      sub='Days'
      content={_ => (disabled ? 'N/A' : value)}
      description='Average days comps are sitting on the market'
      background='/assets/images/dashboard/homeinfo/avarage-days-bg.png'
      info='Measures the number of days a comp is sitting on the market.'
      clicked={onClick}
      borderedCircleColor={color}
      className={styles.card}
      disabled={disabled}
      oneSheeterSlide={oneSheeterSlide}
    />
  );
};
