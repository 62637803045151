import { useMemo, useState } from 'react';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useDispatch, useSelector } from 'react-redux';

import { THighlightedProperty, TProperty } from '../../../types';
import { thumbsUpAtom } from '../state/thumbsUpAtom';
import { getPropertyId } from '../services/getPropertyId';
import { usePresentationFromParams } from './usePresentationFromParams';
import { useMaxPropertiesLimitToast } from './useMaxPropertiesLimitToast';
import { excludedPropertyIdsAtom } from '../state/excludedPropertyIdsAtom';
import { useUpdatePresentation } from '../../../hooks/useUpdatePresentation';
import { propertyToMarker } from '../state/highlighted-properties/propertyToMarker';
import { resultOperations } from '../../../pages/PresentationCreate/dash/store/result';
import { highlightedPropertiesAtom } from '../state/highlighted-properties/highlightedPropertiesAtom';
import { isUpdatePresentationWithBuildAtom } from '../state/isUpdatePresentationWithBuildAtom';
import { pricePerSqftModeAtom } from '../state/pricePerSqftModeAtom';

// TODO - move actions from redux to jotai

export const useActionButtons = (property: TProperty) => {
  const propertyId = getPropertyId(property);
  const setUpdatePresentationWithBuild = useSetAtom(isUpdatePresentationWithBuildAtom);
  const pricePerSqftMode = useAtomValue(pricePerSqftModeAtom);

  const dispatch = useDispatch();
  const excludedIds = useAtomValue(excludedPropertyIdsAtom);

  const { data: presentation } = usePresentationFromParams();

  const { mutate: updatePresentation } = useUpdatePresentation(presentation?.hash);

  const { maxPropertiesLimitToast, canAddMoreItemsToScale } = useMaxPropertiesLimitToast();

  const highlightedProperties = useAtomValue(highlightedPropertiesAtom);
  const thumbsUpProperties = useAtomValue(thumbsUpAtom);

  const [excludeInProgress, setExcludeInProgress] = useState<string | null>(null);

  const isLiked = useMemo(
    () => Boolean(thumbsUpProperties?.find(p => p.id === propertyId)),
    [propertyId, thumbsUpProperties],
  );

  const isFeatured = useMemo(
    () => Boolean(highlightedProperties?.find(p => p.id === propertyId)),
    [highlightedProperties, propertyId],
  );

  const isDeleted = useMemo(() => excludedIds?.includes(propertyId), [excludedIds, propertyId]);

  const dispatchDelete = (propId: string) => async (dispatch: any, getState: any) => {
    await resultOperations.onExclude(
      propId,
      presentation?.type,
      presentation?.id,
      setExcludeInProgress,
      pricePerSqftMode,
    )(dispatch, getState);

    if (!presentation?.id) return;

    const reportData = getState();

    updatePresentation({
      ...presentation,
      reportData,
    });
  };

  const onDelete = (propertyId: string) => {
    dispatch(dispatchDelete(propertyId));
    setUpdatePresentationWithBuild(false);
  };

  const onFeature = (property: THighlightedProperty, removeFromThumbsUp?: boolean) =>
    dispatch(resultOperations.onHighlight(property, removeFromThumbsUp, pricePerSqftMode));

  const onLike = (property: TProperty) =>
    dispatch(resultOperations.thumbsUpToggle(property, true, pricePerSqftMode));

  const onHighlightClick = (property: TProperty) => {
    if (!canAddMoreItemsToScale && !isFeatured) {
      maxPropertiesLimitToast();
      return;
    }

    onFeature(propertyToMarker(property));
  };

  return {
    isLiked,
    isDeleted,
    isFeatured,
    excludeInProgress,
    onDelete,
    onLike,
    onHighlightClick,
  };
};
