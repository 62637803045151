import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { AuthApi } from '../../api/AuthApi';
import { corePresentApi } from '../../api/CorePresentApi';
import { StorageService } from '../../services/storageService';
import { ROUTING_PREFIX } from '../../constants/routingPrefix';
import { REDIRECT_TO_PARAM } from '../../constants/redirectToParam';
import { GuestLayout } from '../../layouts/GuestLayout/GuestLayout';
import { CurrentUserService } from '../../services/CurrentUserService';
import { ANIMATION_DURATION, AnimationLoader } from './AnimationLoader';
import { usePrimaryColorFromSearchParams } from '../../hooks/utils/usePrimaryColorFromSearchParams';
import { constructWhitelabelLoginUrl } from '../../features/whitelabel/constructWhitelabelLoginUrl';
import { useSetSearchParameter } from '../../hooks/useSetSearchParameter';
import { useIsLoggedIn } from '../../features/auth/useIsLoggedIn';
import { TUser } from '../../types';

const Container = styled('div')({
  position: 'fixed',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 50,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingBottom: '15vh',
});

export const SsoPage: React.FC = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState('');
  const [user, setUser] = useState<TUser | null>(null);
  const location = useLocation();
  const query: any = new URLSearchParams(location.search);
  const isWlLogin = usePrimaryColorFromSearchParams();
  const setSearchParam = useSetSearchParameter();

  const redirectTo = query.get(REDIRECT_TO_PARAM);

  const isAuthenticated = useIsLoggedIn();

  async function logoutIfLoggedIn() {
    if (isAuthenticated) {
      await corePresentApi.post('/auth/logout');
      StorageService.remove('token');
      CurrentUserService.remove();
    }
  }

  async function exchangeAuthCodeForToken(query: string) {
    const ssoTimer = new Date();
    const response = await AuthApi.processSsoCallback('?' + query);
    const duration = new Date().getTime() - ssoTimer.getTime();

    return { token: response.token, user: response.user, duration };
  }

  function redirectToHomePage() {
    const baseUrl = window.location.origin + ROUTING_PREFIX;

    if (redirectTo) {
      window.location.href = baseUrl + redirectTo;
      return;
    }

    window.location.href = baseUrl;
  }

  const processSsoLink = async (query: string) => {
    try {
      await logoutIfLoggedIn();
      const response = await exchangeAuthCodeForToken(query);

      StorageService.save('token', response.token);
      setUser(response.user);

      setTimeout(() => {
        setSearchParam('isAuthOnSSO', 'true');
      }, ANIMATION_DURATION - response.duration);
    } catch (e: any) {
      setLoading(false);
      setError(true);
      setMessage('An error occurred. Please get in touch with us.');
    }
  };

  useEffect(() => {
    processSsoLink(query.toString());
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!user || !isAuthenticated) return;

    if (isWlLogin && user?.website) {
      window.location.href = constructWhitelabelLoginUrl(user.website);
      return;
    }

    redirectToHomePage();
  }, [user?.website, isAuthenticated, isWlLogin]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <GuestLayout hideHeader>
      <Container>
        {loading && <AnimationLoader />}
        {error && <p style={{ color: 'red' }}>{message}</p>}
      </Container>
    </GuestLayout>
  );
};
