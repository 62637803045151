export const SELECTED_CATEGORIES_SET = 'result/SELECTED_CATEGORIES_SET';
export const SET_FILTERED_PROPERTIES = 'result/SET_FILTERED_PROPERTIES';
export const HIGHLIGHTED_SET = 'result/HIGHLIGHTED_SET';
export const EXCLUDED_SET = 'result/EXCLUDED_SET';
export const THUMBS_UP_SET = 'result/THUMBS_UP_SET';

export const CUSTOM_ESTIMATES_SET = 'result/CUSTOM_ESTIMATES_SET';

export const NOTES_SET = 'result/NOTES_SET';
export const NOTES_ADD = 'result/NOTES_ADD';
