import { Fragment, useState } from 'react';
import { useAtomValue } from 'jotai';

import { InfoCard } from '../InfoCard/InfoCard';
import { Modal } from '../../../../../components/Modal/Modal';
import { getShelflifeAverage } from '../../../../../../services/getShelfLifeAverage';
import ActiveShelfLife from '../../../../../../../../../components/Slide/slides/marketAnalysis/marketOverview/components/activeShelfLife/web/ActiveShelfLife';
import { reportPropertiesAtom } from '../../../../../../../../../features/report/state/reportPropertiesAtom';

export const AverageDaysToSell = ({ data, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openPopup = () => {
    setIsOpen(true);
  };
  const propertiesRaw = useAtomValue(reportPropertiesAtom);
  const properties = propertiesRaw.map(prop => prop.property);

  const value = getShelflifeAverage(properties);

  return (
    <Fragment>
      <InfoCard
        headline={'Current Active Shelf Life'}
        sub='Days'
        content={_ => (!value ? 'N/A' : value)}
        description='Average days comps are sitting on the market'
        background='/assets/images/dashboard/homeinfo/current-active-shelf-life-dark.svg'
        backgroundLight='/assets/images/dashboard/homeinfo/current-active-shelf-life.svg'
        info='Measures the number of days a comp is sitting on the market.'
        clicked={() => openPopup()}
        disabled={!value}
        className={className}
      />

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} light>
        <ActiveShelfLife category='traditional' />
      </Modal>
    </Fragment>
  );
};
