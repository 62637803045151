import * as types from './types';
import { PROPERTIES_TYPES } from '../../view/constants/propertyType';

export const selectedCategories = (data = [], action) => {
  switch (action.type) {
    case types.SELECTED_CATEGORIES_SET:
      return action.payload;
    default:
      return data;
  }
};

export const filteredProperties = (data = null, action) => {
  switch (action.type) {
    case types.SET_FILTERED_PROPERTIES:
      return action.payload;
    default:
      return data;
  }
};

export const highlighted = (data = [], action) => {
  switch (action.type) {
    case types.HIGHLIGHTED_SET:
      return action.payload;
    default:
      return data;
  }
};

export const excluded = (data = [], action) => {
  switch (action.type) {
    case types.EXCLUDED_SET:
      return action.payload;
    default:
      return data;
  }
};

export const thumbsUp = (data = [], action) => {
  switch (action.type) {
    case types.THUMBS_UP_SET:
      return [...action.payload];
    default:
      return data;
  }
};

export const customEstimates = (estimates = [], action) => {
  switch (action.type) {
    case types.CUSTOM_ESTIMATES_SET:
      return action.payload;
    default:
      return estimates;
  }
};

export const notes = (notes = [], action) => {
  switch (action.type) {
    case types.NOTES_SET:
      return [...action.payload];
    case types.NOTES_ADD:
      return [{ ...action.payload }, ...notes];
    default:
      return notes;
  }
};
