import * as types from './types';

export const setSelectedCategories = step => ({
  type: types.SELECTED_CATEGORIES_SET,
  payload: step,
});

export const setHighlighted = properties => ({
  type: types.HIGHLIGHTED_SET,
  payload: properties,
});

export const setExcluded = properties => ({
  type: types.EXCLUDED_SET,
  payload: properties,
});

export const setThumbsUp = properties => ({
  type: types.THUMBS_UP_SET,
  payload: properties,
});

export const setCustomEstimates = estimates => ({
  type: types.CUSTOM_ESTIMATES_SET,
  payload: estimates,
});

export const setFilteredProperties = data => ({
  type: types.SET_FILTERED_PROPERTIES,
  payload: data,
});

export const addNote = note => ({
  type: types.NOTES_ADD,
  payload: note,
});
