// { property, properties, type, selectProperty }

import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { PROPERTY_STATUS, TProperty } from '../../../../../types';
import { filteredPropertiesAtom } from '../../../state/filteredPropertiesAtom';
import { reportPropertiesAtom } from '../../../state/reportPropertiesAtom';
import { selectedPropertyAtom } from '../../../state/selectedPropertyAtom';
import { subjectAtom } from '../../../state/subjectAtom';
import { compViewModeAtom } from '../../../state/compViewModeAtom';
import { useMemo } from 'react';
import { CompettionMap } from '../../../../../pages/PresentationCreate/dash/view/pages/Authenticated/CompResults/CompsPerformance/Competition/CompettionMap/CompettionMap';
import { FEATURES, useHasFeatureFlag } from '../../../../../hooks/useFeatureFlags';

interface Props {
  type: PROPERTY_STATUS | 'coming_soon' | 'all' | null;
}

export const PropertiesMap = ({ type }: Props) => {
  const filteredProperties = useAtomValue(filteredPropertiesAtom);
  const properties = useAtomValue(reportPropertiesAtom);
  const [selectedProperty, setSelectedProperty] = useAtom(selectedPropertyAtom);
  const subject = useAtomValue(subjectAtom);
  const setCompViewMode = useSetAtom(compViewModeAtom);

  const propertiesToShow = filteredProperties || properties;

  const hasBtTheme = useHasFeatureFlag(FEATURES.BoldTrailTheme);

  const selectProperty = (property: TProperty) => {
    setSelectedProperty(property);
    setCompViewMode('slider');
  };

  const propertiesToDisplay = useMemo(() => {
    return propertiesToShow.filter(p => p.status === type);
  }, [propertiesToShow, type]);

  if (type === 'all') {
    return (
      <CompettionMap
        moreInfo={selectProperty}
        subject={subject}
        properties={propertiesToShow}
        active={selectedProperty}
        noModal={true}
        style={{ height: `500px`, width: `100%` }}
        hasBtTheme={hasBtTheme}
      />
    );
  }

  return (
    <CompettionMap
      moreInfo={selectProperty}
      subject={subject}
      properties={propertiesToDisplay}
      active={selectedProperty}
      noModal={true}
      style={{ height: `500px`, width: `100%` }}
      hasBtTheme={hasBtTheme}
    />
  );
};
