import React, { Fragment, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { InfoCard } from '../InfoCard/InfoCard';
import { Modal } from '../../../../../components/Modal/Modal';
import PricePerSquareFootSlide from '../../../../../../../../../components/Slide/slides/marketAnalysis/marketOverview/components/pricePerSquareFoot/web/PricePerSquareFootSlide';

// PricePerSqft
export const FreshStale = ({ data, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const openPopup = () => {
    setIsOpen(true);
  };
  const all = [
    ...(data?.active ?? []),
    ...(data?.pending ?? []),
    ...(data?.sold ?? []),
    ...(data?.comingSoon ?? []),
  ].filter(prop => !prop.excluded && !!prop.size);

  const calculateAvgPricePerSqft = () => {
    if (!all.length) {
      return 'N/A';
    }

    let sum = 0;
    all.forEach(property => {
      const pricePerSqft =
        property.status === 'sold' ? property.salePricePerSqft : property.pricePerSqft;
      sum = sum + pricePerSqft;
    });
    return Math.round(sum / all.length);
  };

  return (
    <Fragment>
      <InfoCard
        headline='Price per SQFT'
        sup={<FontAwesomeIcon icon={['fas', 'dollar-sign']} fontSize={20} />}
        content={_ => calculateAvgPricePerSqft()}
        description='Average price per square foot of all comparable properties'
        background='/assets/images/dashboard/homeinfo/price-per-sqft-dark.svg'
        backgroundLight='/assets/images/dashboard/homeinfo/price-per-sqft.svg'
        info={`Shows the price per SQFT of all comps on a scatter graph.`}
        clicked={() => openPopup()}
        className={className}
        disabled={!all.length}
      />

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} light>
        <PricePerSquareFootSlide category='traditional' />
      </Modal>
    </Fragment>
  );
};
