import { TCompConfigItem } from '../../../../types';
import { getHighestProperty } from './services/getHighestProperty';
import { getLowestProperty } from './services/getLowestProperty';
import { getMedianProperty } from './services/getMedianProperty';

export enum TRENDS_KEYS {
  AVERAGE = 'AVERAGE',
  RECENT = 'RECENT',
  HIGHEST = 'HIGHEST',
  LOWEST = 'LOWEST',
  CLOSEST = 'CLOSEST',
  MEDIAN = 'MEDIAN',
}

export const COMING_SOON: Record<TRENDS_KEYS, TCompConfigItem> = Object.freeze({
  [TRENDS_KEYS.AVERAGE]: {
    label: 'Average Price',
    labelHtml: 'Average of <span style="color: #6ab4e4">COMING SOON</span> comps',
    id: 'average_coming_soon',
    idPerSqft: 'average_coming_soon_per_sqft',
    customizedData: {
      getProperties: properties => {
        return {
          active: null,
          pending: null,
          sold: null,
          comingSoon: properties.comingSoon,
        };
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.RECENT]: {
    label: 'Most Recent',
    labelHtml: 'Most Recent <span style="color: #6ab4e4">COMING SOON</span> comp',
    id: 'recent_coming_soon',
    idPerSqft: 'recent_coming_soon_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: properties => {
        return properties?.comingSoon
          ?.filter(p => !p.excluded)
          ?.reduce((prev, curr) => (prev.listDate > curr.listDate ? prev : curr));
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.HIGHEST]: {
    label: 'Highest Price',
    labelHtml: 'Highest <span style="color: #6ab4e4">COMING SOON</span> comp',
    id: 'highest_coming_soon',
    idPerSqft: 'highest_coming_soon_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getHighestProperty(properties?.comingSoon, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.LOWEST]: {
    label: 'Lowest Price',
    labelHtml: 'Lowest <span style="color: #6ab4e4">COMING SOON</span> comp',
    id: 'lowest_coming_soon',
    idPerSqft: 'lowest_coming_soon_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getLowestProperty(properties?.comingSoon, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.CLOSEST]: {
    label: 'Nearest',
    labelHtml: 'Nearest <span style="color: #6ab4e4">COMING SOON</span> comp',
    id: 'closest_coming_soon',
    idPerSqft: 'closest_coming_soon_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: properties => {
        return properties?.comingSoon
          ?.filter(p => !p.excluded)
          ?.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr));
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.MEDIAN]: {
    label: 'Median Price',
    labelHtml: 'Median <span style="color: #6ab4e4">COMING SOON</span> comp',
    id: 'median_coming_soon',
    idPerSqft: 'median_coming_soon_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getMedianProperty(properties?.comingSoon || null, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
});

export const ACTIVE: Record<TRENDS_KEYS, TCompConfigItem> = Object.freeze({
  [TRENDS_KEYS.AVERAGE]: {
    label: 'Average Price',
    labelHtml: 'Average of <span style="color: #7EBD39">ACTIVE</span> comps',
    id: 'average_active',
    idPerSqft: 'average_active_per_sqft',
    customizedData: {
      getProperties: properties => {
        return {
          active: properties.active,
          pending: null,
          sold: null,
          comingSoon: null,
        };
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.RECENT]: {
    label: 'Most Recent',
    labelHtml: 'Most Recent <span style="color: #7EBD39">ACTIVE</span> comp',
    id: 'recent_active',
    idPerSqft: 'recent_active_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: properties => {
        return properties?.active
          ?.filter(p => !p.excluded)
          ?.reduce((prev, curr) => (prev.listDate > curr.listDate ? prev : curr));
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.HIGHEST]: {
    label: 'Highest Price',
    labelHtml: 'Highest <span style="color: #7EBD39">ACTIVE</span> comp',
    id: 'highest_active',
    idPerSqft: 'highest_active_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getHighestProperty(properties?.active, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.LOWEST]: {
    label: 'Lowest Price',
    labelHtml: 'Lowest <span style="color: #7EBD39">ACTIVE</span> comp',
    id: 'lowest_active',
    idPerSqft: 'lowest_active_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getLowestProperty(properties?.active, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.CLOSEST]: {
    label: 'Nearest',
    labelHtml: 'Nearest <span style="color: #7EBD39">ACTIVE</span> comp',
    id: 'closest_active',
    idPerSqft: 'closest_active_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: properties => {
        return properties?.active
          ?.filter(p => !p.excluded)
          ?.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr));
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.MEDIAN]: {
    label: 'Median Price',
    labelHtml: 'Median <span style="color: #7EBD39">ACTIVE</span> comp',
    id: 'median_active',
    idPerSqft: 'median_active_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getMedianProperty(properties?.active || null, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
});

export const PENDING: Record<TRENDS_KEYS, TCompConfigItem> = Object.freeze({
  [TRENDS_KEYS.AVERAGE]: {
    label: 'Average Price',
    labelHtml: 'Average of <span style="color: #FF8D00">PENDING</span> comps',
    id: 'average_pending',
    idPerSqft: 'average_pending_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: properties.pending,
        sold: null,
        comingSoon: null,
      }),
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.RECENT]: {
    label: 'Most Recent',
    labelHtml: 'Most Recent <span style="color: #FF8D00">PENDING</span> comp',
    id: 'recent_pending',
    idPerSqft: 'recent_pending_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: properties => {
        return properties?.pending
          ?.filter(p => !p.excluded)
          ?.reduce((prev, curr) => (prev.listDate > curr.listDate ? prev : curr));
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.HIGHEST]: {
    label: 'Highest Price',
    labelHtml: 'Highest <span style="color: #FF8D00">PENDING</span> comp',
    id: 'highest_pending',
    idPerSqft: 'highest_pending_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getHighestProperty(properties?.pending, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.LOWEST]: {
    label: 'Lowest Price',
    labelHtml: 'Lowest <span style="color: #FF8D00">PENDING</span> comp',
    id: 'lowest_pending',
    idPerSqft: 'lowest_pending_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getLowestProperty(properties?.pending, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.CLOSEST]: {
    label: 'Nearest',
    labelHtml: 'Nearest <span style="color: #FF8D00">PENDING</span> comp',
    id: 'closest_pending',
    idPerSqft: 'closest_pending_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: properties => {
        return properties?.pending
          ?.filter(p => !p.excluded)
          ?.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr));
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.MEDIAN]: {
    label: 'Median Price',
    labelHtml: 'Median <span style="color: #FF8D00">PENDING</span> comp',
    id: 'median_pending',
    idPerSqft: 'median_pending_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getMedianProperty(properties?.pending || null, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
});

export const CLOSED: Record<TRENDS_KEYS, TCompConfigItem> = Object.freeze({
  [TRENDS_KEYS.AVERAGE]: {
    label: 'Average Price',
    labelHtml: 'Average of <span style="color: #F13B3B">CLOSED</span> comps',
    id: 'average_closed',
    idPerSqft: 'average_closed_per_sqft',
    customizedData: {
      getProperties: properties => ({
        active: null,
        pending: null,
        sold: properties.sold,
        comingSoon: null,
      }),
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.RECENT]: {
    label: 'Most Recent',
    labelHtml: 'Most Recent <span style="color: #F13B3B">CLOSED</span> comp',
    id: 'recent_closed',
    idPerSqft: 'recent_closed_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: properties => {
        return properties?.sold
          ?.filter(p => !p.excluded)
          ?.reduce((prev, curr) => (prev.saleDate > curr.saleDate ? prev : curr));
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.HIGHEST]: {
    label: 'Highest Price',
    labelHtml: 'Highest <span style="color: #F13B3B">CLOSED</span> comp',
    id: 'highest_closed',
    idPerSqft: 'highest_closed_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getHighestProperty(properties?.sold, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.LOWEST]: {
    label: 'Lowest Price',
    labelHtml: 'Lowest <span style="color: #F13B3B">CLOSED</span> comp',
    id: 'lowest_closed',
    idPerSqft: 'lowest_closed_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        return getLowestProperty(properties?.sold, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.CLOSEST]: {
    label: 'Nearest',
    labelHtml: 'Nearest <span style="color: #F13B3B">CLOSED</span> comp',
    id: 'closest_closed',
    idPerSqft: 'closest_closed_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: properties => {
        return properties?.sold
          ?.filter(p => !p.excluded)
          ?.reduce((prev, curr) => (prev.distance < curr.distance ? prev : curr));
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
  [TRENDS_KEYS.MEDIAN]: {
    label: 'Median Price',
    labelHtml: 'Median <span style="color: #F13B3B">CLOSED</span> comp',
    id: 'median_closed',
    idPerSqft: 'median_closed_per_sqft',
    customizedData: {
      getProperties: properties => properties,
      getHighlight: (properties, sqftModeOn = false, useAdjustedPrice = false) => {
        const prop = sqftModeOn ? 'salePricePerSqft' : 'salePrice';
        return getMedianProperty(properties?.sold || null, sqftModeOn, useAdjustedPrice);
      },
    },
    icon: '/assets/images/dashboard/categories/comp-averages.png',
  },
});
