import React, { Fragment, useState, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { Modal } from '../../../../../components/Modal/Modal';
import { InfoCard, InfoCardTypes } from '../InfoCard/InfoCard';
import classes from './PricingFavorability.module.scss';
import { Loading } from '../../../../../components/Loading/Loading';
import { MoneyFormatter } from '../../../../../../services/moneyFormatter';
import PricingFavorabilitySlide from '../../../../../../../../../components/Slide/slides/marketAnalysis/marketOverview/components/pricingFavorability/web/PricingFavorability';

// This one is for CMA Result page
export const PricingFavorability = ({ data, className = '' }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [activeMonth, setActiveMonth] = useState(null);
  const [options, setOptions] = useState(null);
  const { colors } = useTheme();

  const openPopup = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    const options = data.graph.map((value, key) => {
      return {
        value: key,
        fav: value.favorability,
        label: value.month,
        count: value.count,
        price: value.price,
        sale_count: value.sale_count ?? 0,
        sale_price: value.sale_price ?? 0,
      };
    });
    setOptions(options);
    setActiveMonth(options.length - 1);
  }, []);

  if (options === null) {
    return <Loading />;
  }

  return (
    <Fragment>
      <InfoCard
        headline='Average Monthly List/Sale Price'
        type={InfoCardTypes.CUSTOM}
        content={_ => (
          <div className={classes.priceWrapper}>
            <h2 className={classes.price} style={{ backgroundColor: colors.v2.primary }}>
              {MoneyFormatter.format(options[activeMonth].price)}
            </h2>
          </div>
        )}
        description='Average list price of all monthly active and pending comparables'
        info='Bar graph that shows average list and sale price of all comparables'
        clicked={() => openPopup()}
        className={className}
        background='/assets/images/dashboard/homeinfo/average-monthly-price-dark.svg'
        backgroundLight='/assets/images/dashboard/homeinfo/average-monthly-price.svg'
      />

      <Modal className={classes.modal} isOpen={isOpen} onClose={() => setIsOpen(false)} light>
        <PricingFavorabilitySlide category='traditional' />
      </Modal>
    </Fragment>
  );
};
