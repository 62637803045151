import React, { useEffect, useState } from 'react';

import TopPlacementSvg from './assets/top.svg';
import BottomPlacementSvg from './assets/bottom.svg';
import { CustomizationSettingsExpandableCommon } from '../../../CustomizationModalStepOne.component';
import { useUiConfigInfoPlacement } from '../../../../../../../../../../providers/providers/UiConfigInfoPlacementProvider';
import { useCanUpdateLockStatus } from '../../Lock/useCanUpdateLockStatus';
import { useEntity } from '../../../../../../../../providers/EntityProvider';
import { useUser } from '../../../../../../../../../../components/Slide/providers/UserProvider';
import { getConfigLockedStatus } from '../../../services/getConfigLockedStatus';
import { Lock } from '../../Lock/Lock';
import { Accordion } from '../../../../../../../../../../components/Common/V2/Accordion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { InfoBanner } from '../InfoBanner';
import { getShouldShowInfoBanner } from '../../../services/getShouldShowInfoBanner';

type ExpandablesInfoPlacementComponentProps = CustomizationSettingsExpandableCommon;

export const ExpandablesInfoPlacementComponent: React.FC<
  ExpandablesInfoPlacementComponentProps
> = ({ open, onToggle }) => {
  const { entity } = useEntity();
  const { primaryEntity } = useUser();
  const { infoPlacementData, setInfoPlacement } = useUiConfigInfoPlacement();

  const canUpdateLock = useCanUpdateLockStatus(infoPlacementData.lockedStatus);

  const [isLocked, setIsLocked] = useState<boolean | undefined>(
    getConfigLockedStatus(entity, primaryEntity, infoPlacementData.lockedStatus),
  );

  const [active, setActive] = useState(infoPlacementData.value);

  const onApply = () => {
    setInfoPlacement(prev => {
      const config: any = {
        ...prev,
        value: active,
      };

      if (entity !== null) {
        config.lockedStatus = {
          ...config.lockedStatus,
          [`e-${entity}`]: entity === null ? undefined : isLocked,
        };
      }

      return config;
    });
  };

  useEffect(onApply, [active, entity, isLocked, setInfoPlacement]);

  const showBannerInfo = getShouldShowInfoBanner({
    canUpdateLock,
    isLocked,
  });

  return (
    <Accordion
      title={'Info Placement'.toUpperCase()}
      prefixIcon={<FontAwesomeIcon icon={'border-top'} />}
      open={open}
      onClick={() => onToggle('info')}
    >
      {showBannerInfo && <InfoBanner />}
      <Container>
        <ImgWrapper
          isActive={active === 'top'}
          onClick={() => setActive(isLocked ? active : 'top')}
        >
          <TopPlacementSvg />
        </ImgWrapper>
        <Lock
          onClick={() => setIsLocked(prev => !prev)}
          isLocked={!!isLocked}
          canLock={canUpdateLock}
        />
        <ImgWrapper
          isActive={active === 'bottom'}
          onClick={() => setActive(isLocked ? active : 'bottom')}
        >
          <BottomPlacementSvg />
        </ImgWrapper>
      </Container>
    </Accordion>
  );
};

const Container = styled.div`
  display: flex;
  padding: 20px 15px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  background: ${({ theme }) => theme.colors.v2.background};
  border-radius: 0 0 5px 5px;
`;

const ImgWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s;
  cursor: pointer;
  filter: ${({ isActive }) => `drop-shadow(2px 4px 12px rgba(0, 0, 0, ${isActive ? '0.3' : '0'}))`};
  padding: 1px;
  background: ${({ theme, isActive }) => (isActive ? theme.colors.v2.gradient.accent : 'none')};
  box-shadow: 2px 4px 12px 0px rgba(0, 0, 0, 0.3);
  opacity: ${({ isActive }) => (isActive ? 1 : 0.7)};
  &:hover {
    transform: ${({ isActive }) => `translateY(${isActive ? '0px' : '-1px'})`};
    opacity: 1;
  }
  & > * {
    transform: translate(1.5px, 2px);
  }
`;
