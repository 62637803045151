import { Fragment, useState } from 'react';
import { useAtomValue } from 'jotai';

import { InfoCard } from '../InfoCard/InfoCard';
import { Modal } from '../../../../../components/Modal/Modal';
import { getWeeksOnMarket } from '../../../../../../../../../services/getWeeksOnMarket';
import ActiveCompetition from '../../../../../../../../../components/Slide/slides/marketAnalysis/marketOverview/components/activeCompetition/web/ActiveCompetition';
import { reportPropertiesAtom } from '../../../../../../../../../features/report/state/reportPropertiesAtom';

export const Competition = ({ data, className = '' }) => {
  const properties = useAtomValue(reportPropertiesAtom);
  const [isOpen, setIsOpen] = useState(false);
  const openPopup = () => {
    setIsOpen(true);
  };
  const competitionOpenFunction = data?.count ? () => openPopup() : f => f;

  const onMarket = properties.filter(prop => prop.status === 'active').map(prop => prop.property);
  const countOfFresh = onMarket.filter(p => p.daysOnMarket <= 84).length;
  const countOfStale = onMarket.filter(p => p.daysOnMarket > 84).length;
  const weeksOnMarket = getWeeksOnMarket(onMarket);

  return (
    <Fragment>
      <InfoCard
        headline='Fresh/Stale'
        content={_ => (!data?.count ? 'N/A' : countOfFresh > countOfStale ? 'Fresh' : 'Stale')}
        textContent={true}
        description={`The average active comparable has been on market for ${weeksOnMarket} ${
          weeksOnMarket > 1 ? 'weeks' : 'week'
        }`}
        background='/assets/images/dashboard/homeinfo/fresh-stale-dark.svg'
        backgroundLight='/assets/images/dashboard/homeinfo/fresh-stale.svg'
        info='The count tracks how many comparable listings are currently active on the market.'
        clicked={() => competitionOpenFunction()}
        className={className}
        disabled={!data?.count}
      />

      <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} light>
        <ActiveCompetition category='traditional' />
      </Modal>
    </Fragment>
  );
};
